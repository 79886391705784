<!--  -->
<template>
    <div class='page-task-action'>
        <exercise :sgf="sgf" 
        @gameover="gameover" 
        @nextExercise="exerciseHand"
        :awardCount.sync="awardCount"
        ref="exercise">
            <span slot="title-text" 
            class="text-normal max-width">第{{routeDataCul.page+1}}页第{{currentExNum}}题</span>

            <div class="ex-hand-fun" slot="mask-extend" v-if="!nextExerciseData">
                <!-- <dia-button type="confirm" shape="square" class="flex-center" @click="exerciseHand">
                    <span class="text-normal-a">{{pageText.t1}}</span>
                </dia-button> -->
            </div>
        </exercise>
        <subResults ref="result" @restart="restart"></subResults>
    </div>
</template>

<script>
    import com from '@/views/task/page/subAction.vue'
    import subResults from './subResults.vue'

    export default {
        name: '',
        extends: com,
        props: {},
        data() {
            return {
                page: 0,
                size: 16,
            };
        },
        methods: {
            async exerciseListInit(){
                let {routeDataCul} = this
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    page: routeDataCul.page,
                    size: routeDataCul.size,
                }
                let res = await this.api.userExerciseList(opt)
                if(!res) return
                this.exerciseList = res
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskSubActionsBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskSubActionsBackFun')
            },
            back(){
                let { routeDataCul } = this
                let name = 'task-subject-sections'
                let type = 'replace'
                let params = {...routeDataCul}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
            gameCount(){
                let {routeDataCul,page,size} = this
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    page,
                    size,
                }
                this.$refs['exercise'].stopCountdown()
                this.$refs['result'].open(opt)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'task-subject-actions'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
        },
        created() {
        },
        components: {
            subResults
        },
        computed: {
            exerciseListCul(){
                let {exerciseList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].exNum = Number(i) + 1
                }
                return list
            },
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            // this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
</style>