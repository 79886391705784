<!--  -->
<template>
    <div class="com-task-result-dia">
        <confirmPro ref="confirm">
            <div class='page-task-result-dia flex-center' v-if="active">
                    <div class="result-body light-dark-color">
                        <div class="result-item head" :class="{'c1': fullMark, 'c2': !fullMark}">
                        </div>

                        <div class="result-item b">
                            <div class="b-normal flex-center" v-if="!fullMark">
                                <div class="b-item a">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                    <span class="text-normal-aa bold text-brown-color">答对{{correctNum}}题</span>
                                </div>
                                <div class="b-item b">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c4.png`" class="ex-icon wr">
                                    <span class="text-normal-aa bold text-brown-color">答错{{wrongNum}}题</span>
                                </div>
                            </div>
                            <div class="b-full flex-center" v-else>
                                <div class="b-item">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                    <span class="text-normal-aa bold text-brown-color">{{"满分全对！再接再厉哦~"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-item c">
                            <confirmPlay class="bcg" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" :type="v.type" @click="funHand(v)">
                                <span class="text-normal-ab btn bold">{{v.title}}</span>
                            </confirmPlay>
                        </div>
                    </div>
                </div>
        </confirmPro>
    </div>
</template>

<script>
    // import apiClass from '@/API/page/task'
    import com from './subResult.vue'

    export default {
        name: '',
        props: {
        },
        extends: com,
        data() {
            return {
                // api: new apiClass(),
                subjectData: [],
                // funList: [
                //     {title: "再做一遍", fun: 'restart', type: 'success'},
                //     {title: "错题巩固", fun: 'gotoMistakes'},
                // ],
                // active: false,
                // routeData: this.$route.params.routeData,
            };
        },
        methods: {
            async open(opt){
                opt = opt || {}
                await this.pageInit(opt)
                this.active = true
                await this._common.nextTick.call(this)
                this.$refs['confirm'].open({
                    showClose: true,
                    confirmType: 'c8'
                })
            },
            // close(){
            //     this.active = false
            //     this.$refs['confirm'].close()
            // },
            async pageInit(opt){
                opt = opt || {}
                await this.infoLoad(opt)
            },
            async infoLoad(opt){
                opt = opt || {}
                let res = await this.api.userExerciseList(opt)
                if(!res) return
                this.subjectData = res
            },
            // funHand(val){
            //     let {fun} = val || {}
            //     if(!this[fun]) return
            //     this[fun](val)
            // },
            // async restart(){
            //     this.$emit('restart')
            //     this.close()
            // },
            // gotoMistakes(){
            //     let name = 'mistakes'
            //     let type = 'replace'
            //     this._routeHand.goto({name, type})
            // },
            // eventSet(){
            //     this.eventOff()
            //     this._common.eventSet('taskResultBackFun', e => {
            //         this.back()
            //     })
            // },
            // eventOff(){
            //     this._common.eventRemove('taskResultBackFun')
            // },
            // back(){
            // },
        },
        created() {
            // this.eventSet()
        },
        components: {
        },
        computed: {
            // fullMark(){
            //     return this.wrongNum == 0 && this.correctNum > 0
            // },
            // funListCul(){
            //     let {funList: list} = this
            //     let data = this._callFun.funhandAct.call(this, list)
            //     return data
            // },
            // correctNum(){
            //     let {subjectData: list} = this
            //     let num = 0
            //     for(let i in list){
            //         if(list[i].correct) num++
            //     }
            //     return num
            // },
            // wrongNum(){
            //     let {subjectData: list} = this
            //     let num = 0
            //     for(let i in list){
            //         if(!list[i].correct) num++
            //     }
            //     return num
            // },
            // usercode(){
            //     return this._userHand.usercodeGet()
            // },
            // sectionId(){
            //     let {id: sectionId} = this.routeDataCul
            //     return sectionId
            // },
            // routeDataCul(){
            //     let {routeData} = this
            //     return this._common.routeDataCul(routeData)
            // },
        },
        watch: {
            // $route: {
            //     handler(){
            //         // this.chapterId = this.$route.params.chapterId
            //     },
            //     deep: true,
            //     immediate: true
            // },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    @text-brown-color: #656060;
    .text-brown-color{
        color: @text-brown-color;
    }
    .page-task-result-dia{
        .result-body{
            position: relative;
            height: 323px;
            width: 285px;
            // background: #fff;
            border-radius: 16px;
            .head-text{
                // margin-top: -3px;
            }
            .head-text.continue{
                position: relative;
                top: -28px;
            }
            .result-item.head{
                width: 272px;
                height: 100px;
            }
            .result-item.head.c2{
                .background-img-max(url('@{assetsUrlV2_0}/task/result/c2.png'));
                width: 243px;
                height: 33px;
                position: absolute;
                left: 21px;
                top: 57px;
            }
            .result-item.head.c1{
                .background-img-max(url('@{assetsUrlV2_0}/task/result/c1.png'));
                width: 248px;
                height: 85px;
                position: absolute;
                left: 19px;
                top: 29px;
            }
            .result-item.b{
                // position: relative;
                // margin-top: 3px;
                position: absolute;
                top: 129px;
                left: 0;
                right: 0;
                margin: 0 auto;
                .b-normal{

                }
                .b-item.a{
                    margin-right: 25px;
                }
                .b-item.b{
                }
                .b-item{
                    display: flex;
                    align-items: center;
                }
                .ex-icon{
                    width: 18px;
                    margin-right: 2px;
                }
                .ex-icon.rt{
                    width: 15px;
                    height: 11px;
                }
                .ex-icon.wr{
                    width: 12px;
                }
                .b-item-line{
                    height: 100%;
                    padding: 3px 0px;
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    .line{
                        height: 100%;
                        width: 100%;
                        background: #F5F5F5;
                    }
                }
            }
            .result-item.c{
                // margin-top: 61px;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 181px;
                .com-dia-button+ .com-dia-button{
                    margin-top: 5px;
                }
                .bcg{
                    // .background-img-max(url('@{assetsUrlV2_0}/task/result/c5.png'));
                    width: 137px;
                    height: 46px;
                    margin-top: 10px;
                }
                .restart{
                    .background-img-max(url('@{assetsUrlV2_0}/task/result/c6.png'));
                    color: #1D7626;
                }
                .mistakes{
                    .background-img-max(url('@{assetsUrlV2_0}/task/result/c7.png'));
                    color: #9B1F3E;
                }
                .btn{
                    width: 131px;
                    height: 39px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>